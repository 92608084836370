
         @import "config.scss";
      
.container {
   @extend %container;
   @extend %flexCenter;
   @extend %backgroundTwoColors;
   min-height: 100vh;
}

.link {
   width: 100%;
   text-align: right;
   margin: 4px 5px 8px;

   a {
      @extend %link;
   }
}

.buttons {
   @extend %flexCenter;
   flex-direction: column;
   margin: 10px 0;
   width: 100%;
}

.form {
   @extend %formLoginSignup;

   .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
   }

   .titleSecondary {
      margin-bottom: 12px;
   }
   .subtitle {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.0025em;
      color: rgba(51, 51, 51, 0.8);
      margin: 12px 0px;
   }
}
